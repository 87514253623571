<template>
	<div class="content pd-t-40 pd-b-40">
		<section class="new-year mr-t-40 pd-b-40">
			<div class="hero flex flex-col flex-center flex-just-center mr-b-40">
				<p class="p-1 t-center t-medium mr-b-10">Пусть всё движется!</p>
				<h1 class="t-1 t-center t-w-sb ff-alter mr-b-10">С новым 2025 годом!</h1>
				<p class="t-5 t-center t-c-sc mr-b-40">
					Скачайте файл раскраски и раскрасьте свой год вместе с нами и вашими близкими.
				</p>
				<div class="flex flex-col flex-center flex-just-center">
					<btn @click="downloadPDF" class="download mr-b-10">Скачать раскраску</btn>
					<btn href="#congratulations">Смотреть наше поздравление</btn>
				</div>

				<img src="~@/assets/illustrations/Cooperation/1.svg" alt="TruePositive Robot" class="hero-icon hero-icon-1">
				<img src="~@/assets/illustrations/Cooperation/2.svg" alt="TruePositive Robot" class="hero-icon hero-icon-2">
			</div>
		</section>

		<section class="video-wrapper wrapper pd-b-40 pd-t-40" id="congratulations">
			<iframe
				src="https://vkvideo.ru/video_ext.php?oid=-212623591&id=456239024&hd=2&hash=b4136e346c4482a6"
				width="100%"
				height="480"
				allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
				frameborder="0"
				allowfullscreen
				class="video g-12 mr-b-20"
			>
			</iframe>
			<div class="g-12 flex flex-center flex-just-center">
				<btn href="https://vkvideo.ru/video-212623591_456239024?list=ln-S4z3Fk34ExP407sD8W">Смотреть в ВК</btn>
			</div>
		</section>

		<section class="contacts wrapper pd-t-40 pd-b-40">
			<div class="g-6 bg-light pd-fr-20 br-r-20 p-2">
				<div class="image-wrapper">
					<img src="~@/assets/logos/Knot.svg" alt="Rerotor" class="mr-b-10">
				</div>
				<p class="mr-b-40 t-lh-15">В Новом Году читайте публикации о <span class="t-w-m">Knotinspector</span> в СМИ и не
					только!</p>
				<btn Color="white" href="https://knotinspector.ru/news" class="mr-t-auto">Читать</btn>
			</div>
			<div class="g-6 bg-light pd-fr-20 br-r-20 p-2">
				<div class="image-wrapper">
					<img src="~@/assets/logos/rerotor.svg" alt="Rerotor" class="mr-b-10">
				</div>
				<p class="mr-b-40 t-lh-15">
					Пока за окном снег, узнавайте об обновлениях <span class="t-w-m">RetailRotor</span> и способах использования
					Digital
					Signage.
				</p>
				<div class="flex flex-col mr-t-auto">
					<btn Color="white" href="https://vk.com/wall-212623591_36" class="mr-b-10">ВК</btn>
					<btn Color="white" href="https://t.me/rerotor/42">Telegram канал</btn>
				</div>
			</div>
		</section>
	</div>
</template>

<script setup>
import Btn from '@/components/ui/Btn.vue'

const downloadPDF = () => {
	const fileUrl = '/card-coloring.pdf';
	const a = document.createElement('a');
	a.href = fileUrl;
	a.download = 'card-coloring.pdf';
	document.body.appendChild(a);
	a.click();

	document.body.removeChild(a);
}
</script>

<style lang="scss" scoped>
.content {
	.new-year {
		.hero {
			min-height: 50rem;
			position: relative;

			//.download {
			//	margin-right: 2rem;
			//}

			.hero-icon {
				position: absolute;
				z-index: -1;

				&-1 {
					bottom: 20%;
					left: 0;
				}

				&-2 {
					bottom: 20%;
					right: 0;
				}
			}
		}

		.video-wrapper {
			position: relative;
			padding-top: 2rem;

			.video {
				max-height: 60rem;
				width: 100%;
			}
		}

		.contacts {
			.image-wrapper {
				height: 3rem;
			}
		}
	}
}

@media screen and (max-width: $tablet-landscape-min) {
	.content {
		.new-year {
			.hero {
				.hero-icon {
					&-1 {
						bottom: 20%;
						left: -5%;
					}

					&-2 {
						bottom: 20%;
						right: -5%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $tablet-portrait) {
	.content {
		.new-year {
			min-height: 50%;

			.hero {
				.hero-icon {
					&-1 {
						bottom: 20%;
						left: -5%;
					}

					&-2 {
						bottom: 15%;
						right: -5%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $phone-landscape) {
	.content {
		.new-year {
			.hero {
				.hero-icon {
					&-1 {
						bottom: 20%;
						left: -5%;
					}

					&-2 {
						bottom: 5%;
						right: -15%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $phone-portrait) {
	.content {
		.new-year {
			.hero {
				.hero-icon {
					opacity: 0.7;

					&-1 {
						bottom: 80%;
						left: -5%;
					}

					&-2 {
						bottom: -2%;
						right: 0;
					}
				}
			}
		}
	}
}

$tiny-flip-phone: 240px;
$flip-phone: 320px;
$phone-portrait: 480px;
$phone-landscape: 640px;
$tablet-portrait: 768px;
$tablet-landscape-min: 940px;
$tablet-landscape: 1200px;
$desktop: 1440px;
$xl-desktop: 1920px;
</style>